<template>
  <div class="home-class">
    <div class="power-station">
      <!-- 头部部分 -->
      <div ref="search_d">
        <el-form
          ref="searchform"
          :inline="true"
          :model="queryInfo.condition"
          class="demo-form-inline"
          :rules="rules">
          <el-row>
            <!-- <el-col :span="4"> -->
              <el-select
                v-model="queryInfo.condition.dateFormat"
                placeholder="请选择"
                @change="formatChange"
              >
                <el-option
                  v-for="item in dateFormatList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            <!-- </el-col> -->
            <!-- <el-col :span="6"> -->
              <el-form-item
                v-if="queryInfo.condition.dateFormat == '%Y-%m-%d'"
                prop="summaryDate"
              >
                <el-date-picker
                  v-model="queryInfo.condition.summaryDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  :picker-options="pickerOptions"
                  @change="monthrangeChange(3,queryInfo.condition.summaryDate)"
                  :clearable="false"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                v-else-if="queryInfo.condition.dateFormat == '%Y'"
                prop="summaryDate"
              >
                <el-date-picker
                  v-model="queryInfo.condition.summaryDate"
                  type="year"
                  value-format="yyyy"
                  placeholder="选择年"
                  :picker-options="pickerOptions"
                  @change="monthrangeChange(1,queryInfo.condition.summaryDate)"
                  :clearable="false"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item v-else prop="summaryRangeDate">
                <div>
                  <el-date-picker
                    v-model="queryInfo.condition.summaryRangeDate"
                    type="monthrange"
                    value-format="yyyy-MM"
                    range-separator="-"
                    start-placeholder="开始年月"
                    end-placeholder="结束年月"
                    :picker-options="pickerOptions"
                    @change="monthrangeChange(2,queryInfo.condition.summaryRangeDate)"
                    :clearable="false"
                    style="width: 100%"
                  >
                  </el-date-picker>
                  <!-- <el-date-picker
                    v-model="queryInfo.condition.summaryDate"
                    type="month"
                    value-format="yyyy-MM"
                    placeholder="选择年月"
                    :picker-options="pickerOptions"
                    :clearable="false"
                    style="width: 100%"
                  >
                  </el-date-picker> -->
                </div>
              </el-form-item>
            <!-- </el-col>
            <el-col :span="6"> -->
              <el-form-item>
                <!-- <el-button type="primary" @click="resert">重置</el-button> -->
                <el-button type="primary" @click="search">查询</el-button>
              </el-form-item>
            <!-- </el-col> -->
          </el-row>
          <!-- <el-form-item label="电站名称">
            <el-select v-model="queryInfo.condition.powerStationCode" placeholder="请选择" clearable>
              <el-option v-for="item in powerStationList" :key="item.id" :label="item.powerStationName" :value="item.powerStationCode">
              </el-option>
            </el-select>
          </el-form-item> -->
        </el-form>
      </div>

      <!-- 表格部分 -->
      <div>
        <div class="hand-class" v-loading="exportLoading">
          <img
            src="../../../assets/images/yunwei/export.png"
            @click="exportClick"
            alt=""
          />
        </div>
        <el-table
          border
          ref="table"
          :data="tableData"
          v-loading="loading"
          v-if="dateType"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          tooltip-effect="dark"
          style="width: 100%"
          height="75vh"
        >
          <!-- :height="height" -->
          <el-table-column
            label="序号"
            type="index"
            align="center"
          ></el-table-column>
          <el-table-column
            label="场站名称"
            align="center"
            show-overflow-tooltip
            min-width="160"
          >
            <template slot-scope="scope">{{
              scope.row.powerStationName
            }}</template>
          </el-table-column>
          <el-table-column
            label="总发电量(kWh)"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.powerNum }}</template>
          </el-table-column>
          <el-table-column label="收益" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.income }}</template>
          </el-table-column>
          <el-table-column
            label="运行发电时长(天)"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.daily }}</template>
          </el-table-column>
          <el-table-column
            label="巡检次数"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.pollingCount }}</template>
          </el-table-column>
          <el-table-column
            label="故障报警次数"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.alarmCount }}</template>
          </el-table-column>
        </el-table>

        <el-table
          border
          ref="table"
          :data="tableData"
          v-loading="loading"
          v-else
          element-loading-background="rgba(0, 0, 0, 0.5)"
          tooltip-effect="dark"
          style="width: 100%"
          height="77vh"
        >
          <!-- :height="height" -->
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            label="电站名称"
            align="center"
            show-overflow-tooltip
            min-width="160"
          >
            <template slot-scope="scope">{{
              scope.row.powerStationName
            }}</template>
          </el-table-column>
          <el-table-column
            label="当日发电量"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.powerNum }}</template>
          </el-table-column>
          <el-table-column
            label="当日收益"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.income }}</template>
          </el-table-column>
          <el-table-column label="天气" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.weather }}</template>
          </el-table-column>
          <el-table-column
            label="是否巡检"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{
              scope.row.pollingCount == 0 ? "否" : "是"
            }}</template>
          </el-table-column>
          <el-table-column
            label="故障情况"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">{{
              scope.row.alarmCount == 0 ? "正常" : "故障"
            }}</template>
          </el-table-column>
          <el-table-column
            label="处理方式"
            align="center"
            show-overflow-tooltip
          >
             <template slot-scope="scope">
             无
            </template>
          </el-table-column>

          <el-table-column label="处理人" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
             无
            </template>
          </el-table-column>

          <!--
          <el-table-column label="备注" align="center" show-overflow-tooltip min-width="140">
            <template slot-scope="scope">{{ scope.row.remark }}</template>
          </el-table-column> -->
        </el-table>
        <!-- 分页 -->
        <!-- <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/formatDate";
export default {
  data() {
    return {
      dateType: true,
      rules: {
        summaryDate: [{ required: true, message: "请选择", trigger: "blur" }],
        summaryRangeDate: [{ required: true, message: "请选择", trigger: "change" }],
      },
      total: 0,
      // summaryRangeDate:[],
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {
          dateFormat: "%Y-%m",
          summaryDate: '',
          summaryBeDate: '',
          summaryEddDate: '',
          summaryRangeDate: ''
        },
      },
      userCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
      form: {},
      powerStationList: [], //电站
      dateFormatList: [
        { label: "按年统计", value: "%Y" },
        { label: "按月统计", value: "%Y-%m" },
        { label: "按日统计", value: "%Y-%m-%d" },
      ],
      loading: false,
      exportLoading: false,
      tableData: [],
      height: window.innerHeight - 290, //表格高度
      headerHeight: 0,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },

  created() {
    const nowdate = formatDate(new Date(), "yyyy-MM")
    this.queryInfo.condition.dateFormat = "%Y-%m",
    this.queryInfo.condition.summaryDate = nowdate,
    this.queryInfo.condition.summaryBeDate = nowdate +'-01',
    this.queryInfo.condition.summaryEddDate = this.getLastDayOfMonth(nowdate.split('-')[0],nowdate.split('-')[1]),
    this.queryInfo.condition.summaryRangeDate = [nowdate,nowdate]
    console.log('??',this.queryInfo,this.queryInfo.condition)
    // this.getSelectList();
  },

  mounted() {
    this.getList();
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 190;
    },
    formatChange(val) {
      this.queryInfo.condition.summaryDate = "";
      this.queryInfo.condition.summaryRangeDate = []
    },
    monthrangeChange(index,val){
      switch(index){
        case 1:
          this.queryInfo.condition.summaryBeDate = val+'-01-01'
          this.queryInfo.condition.summaryEddDate = val+'-12-31'
          break;
        case 2:
          this.queryInfo.condition.summaryDate = "";
          this.queryInfo.condition.summaryBeDate = val[0]+'-01'
          const ym = val[1].split('-')
          this.queryInfo.condition.summaryEddDate = this.getLastDayOfMonth(ym[0],ym[1])
          break;
        case 3:
          this.queryInfo.condition.summaryBeDate = val
          this.queryInfo.condition.summaryEddDate = val
          break;
      }
    },
    getLastDayOfMonth(year, month) {
      // 创建一个新的Date对象，表示指定年份和月份的第一天
      let date = new Date(year, month, 1);
      // 将日期设置为下个月的第0天，即上个月的最后一天
      date.setDate(0);
      // 返回月末的日期
      const _date = date.toLocaleString('zh-CN').slice(0, 10).split('/').join('-')
      return _date
    },
    getSelectList() {
      // 电站列表
      this.$http
        .post("powerStation/list", { currPage: 1, pageSize: 100 })
        .then((res) => {
          if (res.data.code == 200) {
            this.powerStationList = res.data.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    //获取数据 /dataStickEveryMonthPower/list
    getList() {
      var that = this;
      this.loading = true;
      // this.queryInfo.condition.userCode = this.userCode;
      const queryInfo = that.queryInfo.condition
      // delete queryInfo.summaryRangeDate
      that.$http
        .post(
          "/powerStationDeviceSummary/stationSummary",
          queryInfo
        )
        .then(function (response) {
          console.log(response);
          if (response.data.code == 200) {
            // that.total = response.data.data.count;
            // that.queryInfo.currPage = response.data.data.currPage
            that.tableData = response.data.data;
            that.loading = false;
          } else {
            that.$message.error(response.data.message);
            that.loading = false;
          }
        });
    },

    // 重置搜索
    resert() {
      this.queryInfo.condition = {
        dateFormat: "%Y-%m",
        summaryDate: Date.now(),
      };
    },

    search() {
      // searchform
      this.$refs["searchform"].validate((valid) => {
        if (valid) {
          this.tableData = [];
          this.getList();
          this.dateType =
            this.queryInfo.condition.dateFormat == "%Y-%m" ? true : false;
        } else {
          this.$message.warning("请选择日期");
        }
      });
    },

    // 导出
    exportClick() {
      let that = this;
      this.$refs["searchform"].validate((valid) => {
        if (valid) {
          this.exportLoading = true
          const _name =
            that.queryInfo.condition.dateFormat == "%Y"
              ? "电站发电量统计数据(按年统计).xlsx"
              : that.queryInfo.condition.dateFormat == "%Y-%m"
              ? "电站发电量统计数据(按月统计).xlsx":"电站发电量统计数据(按日统计).xlsx";
          // let _data = { ...that.queryInfo.condition }
          let _data = {
            dateFormat: that.queryInfo.condition.dateFormat,
            summaryBeDate: that.queryInfo.condition.summaryBeDate,
            summaryDate:that.queryInfo.condition.summaryDate,
            summaryEddDate: that.queryInfo.condition.summaryEddDate,
          }
          // console.log('alj',_data)
          // return
          that
            .$http({
              method: "get",
              url: "powerStationDeviceSummary/stationSummaryExport",
              responseType: "blob",
              params: _data,
            })
            .then((res) => {
              // 创建一个新的URL代表指定的File对象或Blob对象
              const url = URL.createObjectURL(new Blob([res.data]));
              // 创建一个a标签用于下载
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", _name); // 下载文件名
              link.style.display = "none";
              document.body.appendChild(link);
              link.click();
              this.exportLoading = false

              // 清理
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            })
            .catch((err) => {
              this.exportLoading = false
              that.$message.error(err);
            });
        } else {
          that.$message.warning("请选择日期");
        }
      });
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.home-class {
  background: #223f6c;
  padding: 10px;
  height: 100%;

  .power-station {
    height: 100%;
    background: #2d5981;
    padding: 8px;
    overflow-y: auto;

    .hand-class {
      width: 100%;
      // display: flex;
      text-align: right;
      // float: right;
      // margin-bottom: 5px;

      img {
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        margin: 0 5px;
      }
    }

    .demo-form-inline {
      .el-form-item {
        margin-bottom: 0;
      }
    }
    .form-class {
      ::v-deep .el-form-item__label {
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
        font-size: 16px;
      }

      ::v-deep .el-input__inner {
        border: 1px solid rgba(151, 179, 203, 0.55);
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
      }
    }

    ::v-deep .el-input-group__append {
      background: #409eff;
      color: #fff;
    }

    /deep/ .el-form-item__label {
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
      font-size: 16px;
    }

    ::v-deep .el-input__inner ,::v-deep .el-range-input{
      border: 1px solid rgba(151, 179, 203, 0.55);
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #f2f2f2;
      text-align: left;
      line-height: normal;
    }
    ::v-deep .el-range-input{
      border: none;
      text-align: center;
    }
    ::v-deep .el-range-separator{
      color: #f2f2f2;
    }
    ::v-deep .el-table--border,
    .el-table--group {
      border: 1px solid rgba(151, 179, 203, 0.55);
    }

    ::v-deep .el-table--border th {
      background-color: #21527e;

      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    ::v-deep .el-table th {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    ::v-deep .el-table td {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    ::v-deep .el-table__body-wrapper {
      background: #2d5981;
    }

    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: #223f6c !important;
    }

    ::v-deep .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    ::v-deep .el-pagination button:disabled {
      background: rgba(255, 255, 255, 0);
    }

    ::v-deep .el-pager li.active {
      color: #f2f2f2;
      background-color: #00b2e6;
      cursor: default;
    }

    ::v-deep .el-pager li {
      background: none;
      color: #f2f2f2;
    }

    ::v-deep .el-icon {
      color: #fff;
    }

    ::v-deep .el-pagination .btn-prev {
      background: none;
    }

    ::v-deep .el-pagination .btn-next {
      background: none;
    }

    ::v-deep .el-pagination__total {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 12px;
    }

    ::v-deep .el-pagination__jump {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 15px;
    }

    ::v-deep .el-icon-close:before {
      color: #333;
    }

    ::v-deep .el-upload {
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
  }
}
.el-loading-mask{
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
